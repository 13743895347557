<template>
  <div class="domains-table-check standart-text">
    <div v-for="(item, index) in mappedList" :key="index" class="l-flex domains-table-check__item">
      <div class="l-col-8_sm-5">
        <div class="domains-table-check__name">{{ item.name }}</div>
      </div>
      <div class="l-col-2_sm-2">
        <div class="domains-table-check__status" :class="`${item.status.color}-color`">
          {{ item.status.title }}
        </div>
      </div>
      <div class="l-col-4_sm-4">
        <div class="domains-table-check__cost" :class="{ ['note-text']: !item.status.action }">
          {{
            $t('costInPeriod', {
              cost: $n(item.cost, 'currency'),
              period: $tc(`period.${item.periodType}_c`, item.periodLen),
            })
          }}
        </div>
      </div>
      <div class="l-col-4_sm-1">
        <div class="domains-table-check__action">
          <span v-if="!item.status.action" class="disabled note-text">
            {{ $t('actions.disabled') }}
          </span>
          <plain-button v-else color="primary" @click="item.status.action.handler(item)">
            {{ $t(`actions.${item.status.action.name}`) }}
          </plain-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseColors } from '@/models/base/baseColors';
export default {
  name: 'DomainsTableCheck',
  props: {
    domain: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statuses: {
        free: {
          title: this.$t('status.free'),
          color: baseColors.success,
          action: {
            name: 'add',
            handler: this.add,
          },
        },
        busy: {
          title: this.$t('status.busy'),
          color: baseColors.error,
          action: null,
        },
      },
    };
  },
  computed: {
    mappedList() {
      return this.list.map(i => {
        return {
          ...i,
          status: this.statuses[i.status],
        };
      });
    },
  },
  methods: {
    add(item) {
      this.$emit('order', item);
    },
  },
};
//test
</script>

<i18n>
{
  "ru": {
    "status": {
      "free": "свободен",
      "busy": "занят"
    },
    "actions": {
      "add": "добавить +",
      "disabled": "недоступно"
    },
    "costInPeriod": "%{cost} в %{period}"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.l-col-4_sm-4{
  padding-left: 30px;
}
.domains-table-check {
  &__item {
    & + & {
      margin-top: 0.75rem;
    }
  }
  &__action {
    +breakpoint(sm-and-up) {
      text-align: right;
    }
  }
  &__name {
    +breakpoint(ms-and-down) {
      font-weight: $font-weight-medium;
    }
  }
}
</style>
